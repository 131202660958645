import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faLinkedinIn,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';
import SBAImage from '../../images/sba.png';
import ISO9001Image from '../../images/ISO_9001_2015_Standards_Logo.png';
import SDVOSBImage from '../../svg/SDVOSB.svg';
import WOSBImage from '../../images/WOSB.png';
import lightFeatherISOCert from '../../pdf/ISO-9001_2015-Standards-Certificate.pdf';

const Footer = () => (
  <footer>
    <div className='bg-blue-darker sticky bottom-0'>
      <div className='container flex flex-col sm:flex-row justify-between mx-auto py-4 px-8  text-white'>
        <div className='flex px-3 text-center mx-auto'>
          <h2>Connect With Us</h2>
        </div>
        <div className='flex px-3 text-center mx-auto'>
          <ul className='pl-0'>
            <li className='float-left px-3'>
              <a
                className='iconLinks'
                href='https://www.facebook.com/LightFeatherio-108645090838842'
                rel='noopener'
                aria-label='facebook'
              >
                <FontAwesomeIcon icon={faFacebook} size='2x' />
              </a>
            </li>
            <li className='float-left px-3'>
              <a
                className='iconLinks'
                href='https://www.linkedin.com/company/lightfeather-io/mycompany/'
                rel='noopener'
                aria-label='linkedin'
              >
                <FontAwesomeIcon icon={faLinkedinIn} size='2x' />
              </a>
            </li>
            <li className='float-left px-3'>
              <a
                className='iconLinks'
                href='https://twitter.com/Lightfeatherio'
                rel='noopener'
                aria-label='twitter'
              >
                <FontAwesomeIcon icon={faTwitter} size='2x' />
              </a>
            </li>
            <li className='float-left px-3'>
              <a
                className='iconLinks'
                href='https://www.instagram.com/lightfeather.io/'
                rel='noopener'
                aria-label='instagram'
              >
                <FontAwesomeIcon icon={faInstagram} size='2x' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <div className='container mx-auto max-w-1015-px py-10'>
        <div className='flex flex-wrap'>
          <div className='p-5 m-auto'>
            <a
              href={lightFeatherISOCert}
              alt='LightFeatherIO ISO PDF'
              target='_blank'
              rel='noreferrer'
            >
              <img width='179' src={ISO9001Image} alt='ISO9001Image' />
            </a>
          </div>
          <div className='p-5 m-auto'>
            <img width='179' src={SDVOSBImage} alt='SDVOSBImage' />
          </div>
          <div className='p-5 m-auto'>
            <img width='179' src={WOSBImage} alt='WOSBImage' />
          </div>
          <div className='p-5 m-auto'>
            <img width='179' src={SBAImage} alt='SBAImage' />
          </div>
        </div>
      </div>
    </div>
    <div className='bg-blue'>
      <div className='container flex flex-col sm:flex-row justify-between mx-auto py-4 px-8 text-white'>
        <div className='flex px-3 m-auto'>
          <ul className='pl-0'>
            <li className='float-left px-3'>
              <a className='iconLinks' href='/careers' rel='noopener'>
                Careers
              </a>
            </li>
            <li className='float-left px-3'>
              <a className='iconLinks' href='/about-us' rel='noopener'>
                About Us
              </a>
            </li>
            <li className='float-left px-3'>
              <a className='iconLinks' href='/privacy' rel='noopener'>
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='container flex flex-col sm:flex-row justify-between mx-auto py-4 px-8 text-white'>
        <div className='flex m-auto'>© 2023 LIGHTFEATHER IO LLC, All Rights Reserved.</div>
      </div>
    </div>
  </footer>
);

export default Footer;
