import React from 'react';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

const Button = ({ onClick, children, className, size, disabled }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={`
        ${sizes[size] || sizes.default}
        bg-blue-lighter hover:bg-blue-darker text-white
        rounded
        elevation-24
        ${className}
    `}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
