import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => (
  <>
    <Header />
    <main className='min-h-500-px'>{children}</main>
    <Footer />
  </>
);

export default Layout;
