import React, { useState } from 'react';
import LogoIcon from '../../svg/LogoIcon';
import { Link } from 'gatsby';
import Navigation from './Navigation';
import Button from '../Button';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className='fixed top-0 z-50 w-full flex bg-white items-center flex-wrap justify-between p-3 border-b-2 shadow-sm'>
      <div className='flex items-center flex-shrink-0 mr-6'>
        <Link to='/'>
          <LogoIcon />
        </Link>
      </div>
      <div className='block lg:hidden'>
        <Button onClick={() => setIsOpen(!isOpen)}>
          <svg
            className='fill-current h-3 w-3'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </Button>
      </div>
      <div className='w-full block flex-grow lg:flex lg:m-auto lg:items-center lg:w-auto mt-5'>
        <div className='text-sm lg:flex-grow'></div>
        <div className={`${isOpen ? 'block' : 'hidden'}` + ' lg:hidden'}>
          <Navigation />
        </div>
        <div className=' hidden lg:block'>
          <Navigation />
        </div>
      </div>
    </nav>
  );
};
export default Header;
