import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Menu, Transition } from '@headlessui/react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MenuItemButton = ({ children, to, className }) => (
  <Link to={to}>
    <button
      type='button'
      className={`
      py-2
      px-8
      bg-white
      hover:bg-blue-lighter
      rounded
      text-black
      text-left 
      md:text-center
      hover:text-white
      w-full
      ${className}
  `}
    >
      {children}
    </button>
  </Link>
);

const MainNav = () => (
  <>
    <MenuItemButton className='md:w-auto' to='/about-us'>
      About Us
    </MenuItemButton>
    <Menu as='div' className='relative inline-block w-full md:w-auto mt-0'>
      <Menu.Button className='inline-flex w-full px-8 py-2 hover:text-white hover:bg-blue-lighter rounded'>
        Capabilities
        <div className='mr-3'>
          <FontAwesomeIcon className='mx-3 hover:text-blue' icon={faAngleDown} />
        </div>
      </Menu.Button>
      <Transition
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='origin-top-right absolute z-10 right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <Menu.Item className='border-b-2'>
            <MenuItemButton to='/capabilities-statement'>Capabilities Statement</MenuItemButton>
          </Menu.Item>
          <Menu.Item className='border-b-2'>
            <MenuItemButton to='/lightfeather-llc-catalog'>
              LightFeather GSA MAS IT Catalog
            </MenuItemButton>
          </Menu.Item>
          <Menu.Item className='border-b-2'>
            <MenuItemButton to='/vq-feather-llc-catalog'>
              VQ Feather GSA MAS IT Catalog
            </MenuItemButton>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
    <MenuItemButton className='md:w-auto' to='/contract-vehicles'>
      Contract Vehicles
    </MenuItemButton>
    <MenuItemButton className='md:w-auto' to='/careers'>
      Careers
    </MenuItemButton>
  </>
);

export default MainNav;
